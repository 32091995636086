//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2021 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

h1
  color:              var(--h1-font-color)
  font-weight:        bold
  padding:          0 0 8px 0
  margin:        0

h2
  color:              var(--h2-font-color)
  font-weight:        bold
  padding:            0 0 8px 0
  margin:             0

h3
  color:              var(--h3-font-color)
  font-weight:        normal
  border-bottom:      1px solid #dddddd
  padding:            0 0 8px 0
  margin:             0 0 20px 0

h4
  color:              var(--h4-font-color)
  font-weight:        normal
  border-bottom:      1px dotted #bbbbbb
  padding:            0 0 5px 0
  margin:             0 0 20px 0

h5
  color:              var(--h5-font-color)
  font-weight:        bold
  border-bottom:      none
  padding:            0
  margin:             10px 0 0 0
